
import { defineComponent } from "vue";
import {encashmentprocessinitialload, encash, processChinatrustOTP, processEncashmentOTP}  from "@/api/encashment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import axios from 'axios';


export default defineComponent({
  name: "encashmentprocess", 
  data(){
      return { 
        hasPendingChinatrustOTP : false,
        hasPendingEncashmentOTP : false, 
        isAllowBankEncashment : false,
        isShowEncashmentDestinationDropdown : false,
        isAllowChequeEncashment : false,
        isAllowCashEncashment : false, 
        isAllowIbayadEncashment : false, 
        ccode : 0,
        isShowChinatrustEncashDropdown : false,
        isShowBiboEncashDropdown: false,
        isShowMobileMoneyEncashDropdown : false,
        ibayadmobile : "",
        isShowChequeBranchDropdown : "", 
        fname : "",
        lname : "",
        b : "",
        ba : "",
        bb : "",
        cta : "",
        model_destination_selected : "XXX",
        model_encashment_amount : "",
        isShowEncashmentButtons : false,
        isShowEncashmentConfirmation : false,
        isShowBiboEncashmentConfirmation:false,
        isShowMobileMoneyEncashmentConfirmation : false,
        isShowChequeEncashmentConfirmation : false,
        isShowCashEncashmentConfirmation : false,
        isCancelBtnDisabled : true,
        isEncashBtnDisabled : true,
        branches : [],
        model_chinatrust_otp : "",
        model_encashment_otp : "",
        isCancelChequeBtnDisabled : true,
        isChequeEncashBtnDisabled : true,
        isShowDivChequeBranch : false,
        isShowDivCashBranch : false,
        chequereleasebranchcode_selected : "",
        cashreleasebranchcode_selected : "",
        loading: true,
        display_b: "",
        display_ba: "",
        bibo_mobile: "",
        need_cheque_id_upload : false,
        need_cheque_id_selfie_upload : false,
        currentlyUploading : false,
        wbalance_mlc : 0.00,
        wbalance_drc : 0.00,
        usid : "",
        currency : "",
        encashFrom : "MLC",
        isShowMLCEncashment : false,
        availablebalance : 0.00,
        showEncashmentForm : true,
        mobileno : "",
        mobile_money_accountno : "",
        isRequiredNigeriaLGA : false,
        isRequiredKenyaKRA : false
      }
  },
  methods: {
    async encashmentprocessinitialload(){ 
      this.loading = true;
        const response = await encashmentprocessinitialload();

        console.log(response.data);



        this.hasPendingChinatrustOTP = response.data.hasPendingChinatrustOTP;
        this.hasPendingEncashmentOTP = response.data.hasPendingEncashmentOTP;
        this.isAllowBankEncashment = response.data.isAllowBankEncashment;
        this.isShowEncashmentDestinationDropdown = response.data.isShowEncashmentDestinationDropdown;
        this.isAllowChequeEncashment = response.data.isAllowChequeEncashment;
        this.isAllowCashEncashment = response.data.isAllowCashEncashment;
        this.isAllowIbayadEncashment = response.data.isAllowIbayadEncashment;
        this.ccode = response.data.ccode;
        this.isShowChinatrustEncashDropdown = response.data.isShowChinatrustEncashDropdown;
        this.isShowBiboEncashDropdown = response.data.isShowBiboEncashDropdown;
        this.isShowMobileMoneyEncashDropdown = response.data.isShowMobileMoneyEncashDropdown;
        this.ibayadmobile = response.data.ibayadmobile;
        this.isShowChequeBranchDropdown = response.data.isShowChequeBranchDropdown;
        this.fname = response.data.fname;
        this.lname = response.data.lname;
        this.b = response.data.b;
        this.ba = response.data.ba;
        this.bb  = response.data.bb;
        this.cta = response.data.cta;
        this.branches = response.data.branches;
        this.isCancelBtnDisabled = true;
        this.isEncashBtnDisabled = true;
        this.loading = false;
        this.display_b = this.b;
        this.display_ba = this.ba;
        this.bibo_mobile = response.data.bibo_mobile;
        this.wbalance_mlc = response.data.wbalance_mlc;
        this.wbalance_drc = response.data.wbalance_drc;
        this.usid = String(localStorage.getItem("ec_current_usid"));
        this.currency = response.data.currency;
        this.isShowMLCEncashment = response.data.isShowMLCEncashment;
        this.availablebalance = response.data.availablebalance;
        this.mobile_money_accountno = response.data.mobile_money_accountno;
        
        this.isRequiredNigeriaLGA = response.data.isRequiredNigeriaLGA;
        this.isRequiredKenyaKRA = response.data.isRequiredKenyaKRA;

        if(this.ccode == 0) {
          this.showEncashmentForm = false;
        }
        
      },

      async destinationChange(){
        this.isShowEncashmentConfirmation = false;
        this.isShowBiboEncashmentConfirmation=false;
        this.isShowMobileMoneyEncashmentConfirmation = false;
        this.isShowChequeEncashmentConfirmation = false;
        this.isShowCashEncashmentConfirmation = false;
        this.isCancelBtnDisabled = false;
        this.isEncashBtnDisabled = false;
        this.isShowEncashmentButtons = true;
        
        if(this.model_destination_selected == 'B') {  
          this.isShowDivChequeBranch = false;
          this.isShowDivCashBranch = false;
        } else if(this.model_destination_selected == 'E'){
           this.isShowDivChequeBranch = false;
           this.isShowDivCashBranch = false;
        } else if(this.model_destination_selected == 'C'){
           this.isShowDivChequeBranch = true;
           this.isShowDivCashBranch = false;
           //this.chequereleasebranchcode_selected = "XXX";
        } else if(this.model_destination_selected == 'S'){
           this.isShowDivChequeBranch = false;
           this.isShowDivCashBranch = true;
          //this.cashreleasebranchcode_selected = "XXX";
        } else if(this.model_destination_selected == 'T'){
           this.isShowDivChequeBranch = false;
           this.isShowDivCashBranch = false; 
        } else if(this.model_destination_selected == 'M'){
           this.isShowDivChequeBranch = false;
           this.isShowDivCashBranch = false; 
        } else {
          this.isCancelBtnDisabled = true;
          this.isEncashBtnDisabled = true;
        }
      },
      async process_encashment(){
        var amount = this.model_encashment_amount;
        var destination = this.model_destination_selected;
        if(destination === 'R' || destination === 'B' || destination === 'D' || destination === 'E') {
          this.confirm_encashment();
        } else if(destination === 'C'){
          this.confirm_CHEQUEencashment();
        } else if(destination === 'T'){
          this.confirm_BIBOencashment();
        } else if(destination === 'M'){
          this.confirm_MobileMoneyencashment();
        } else if(destination === 'S'){
          this.confirm_CASHencashment();
	      }
      },


      async confirm_MobileMoneyencashment(){
        this.isShowEncashmentButtons = false;
        this.isShowEncashmentConfirmation = false;
        this.isShowBiboEncashmentConfirmation = false;
        this.isShowChequeEncashmentConfirmation = false;
        this.isShowCashEncashmentConfirmation = false;
        this.isShowMobileMoneyEncashmentConfirmation = true;
        
      },

      async confirm_BIBOencashment(){
        this.isShowEncashmentButtons = false;
        this.isShowEncashmentConfirmation = false;
        this.isShowBiboEncashmentConfirmation = true;
        this.isShowChequeEncashmentConfirmation = false;
        this.isShowCashEncashmentConfirmation = false;
        this.isShowMobileMoneyEncashmentConfirmation = false;
        
      },
      async confirm_CHEQUEencashment(){
        this.isShowEncashmentButtons = false;
        this.isShowEncashmentConfirmation = false;
        this.isShowBiboEncashmentConfirmation = false;
        this.isShowChequeEncashmentConfirmation = true;
        this.isShowCashEncashmentConfirmation = false;
        this.isShowMobileMoneyEncashmentConfirmation = false;
        
      },
      async confirm_CASHencashment(){
        this.isShowEncashmentButtons = false;
        this.isShowEncashmentConfirmation = false;
        this.isShowBiboEncashmentConfirmation = false;
        this.isShowChequeEncashmentConfirmation = false;
        this.isShowCashEncashmentConfirmation = true;
        this.isShowMobileMoneyEncashmentConfirmation = false;
      },


      async confirm_encashment(){
        this.isShowEncashmentButtons = false;
        this.isShowEncashmentConfirmation = true;
        this.isShowBiboEncashmentConfirmation = false;
        this.isShowChequeEncashmentConfirmation = false;
        this.isShowCashEncashmentConfirmation = false;
        this.isShowMobileMoneyEncashmentConfirmation = false;

        var destination = this.model_destination_selected;

        if(destination==='R') {

        } else if(destination==='E'){
          this.display_b = "Chinatrust";
          this.display_ba = this.cta;
        }
        else {
          
        }
      }, 
      async process_ibayadencashment() {
        this.loading = true;
        var amount = this.model_encashment_amount;
        var destination = this.model_destination_selected;
        var param = {"amount" : amount, "destination" : destination, "encashFrom" : this.encashFrom};

        this.isCancelBtnDisabled = true;
        this.isEncashBtnDisabled = true;

        const response = await encash(param);
        if(response != null){
          if (response.data.result == "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
              
            }); 
            window.location.reload();
            return; 
          } else if(response.data.result == "FAILED"){
            
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              
            }); 
            this.cancel_encashmentconfirmation();
            return; 
          }  else if(response.data.result == "CHINATRUST"){
            //$('#hiddenChinatrustMemoButton').click();
            this.cancel_encashmentconfirmation();
          }
        }
        this.loading = false;
      },
      async cancel_encashmentconfirmation(){
        this.reset_encashment();

      },

      async process_chequeencashment(){
        this.loading = true;
        var amount = this.model_encashment_amount;
        var destination = this.model_destination_selected;
        var chequereleasebranchcode = this.chequereleasebranchcode_selected;
        var param = {"amount" : amount, "destination" : destination, "chequereleasebranchcode":	chequereleasebranchcode, "encashFrom" : this.encashFrom};
        

        // if(this.ccode == 2 || this.ccode == 3 || this.ccode == 4 || this.ccode == 7 || this.ccode == 15 || this.ccode == 20 || this.ccode == 25 || 
        // this.ccode == 26 || this.ccode == 28 || this.ccode == 32 || this.ccode == 35 || this.ccode == 39){
        //   var chequereleasebranchcode = "001";
        // }

        if(this.ccode != 0){
          var chequereleasebranchcode = "001";
        } 


        if(chequereleasebranchcode == ''){
          Swal.fire({
              title: "",
              text: "Please select a branch for cheque release.",
              icon: "error",
              
            }); 
        } else {
          this.isCancelBtnDisabled = true;
          this.isEncashBtnDisabled = true;
          const response = await encash(param);
          if(response != null){
            
            if (response.data.result == "SUCCESS") {
              Swal.fire({
                title: "",
                text: response.data.message,
                icon: "success",
                
              }); 
              window.location.reload();
            } else if(response.data.result == "FAILED"){
              
              Swal.fire({
                title: "",
                text: response.data.message,
                icon: "error",
                
              }); 
              this.cancel_encashmentconfirmation();
            } 
          }
        }
        this.loading = false;
        return;
      },

      async process_cashencashment(){
        this.loading = true;
        var amount = this.model_encashment_amount;
        var destination = this.model_destination_selected;
        var cashreleasebranchcode = this.cashreleasebranchcode_selected;
        var param = {"amount" : amount, "destination" : destination, "cashreleasebranchcode":	cashreleasebranchcode, "encashFrom" : this.encashFrom};
      
      if(this.ccode != 0){
          var cashreleasebranchcode = "001";
        } 

        if(cashreleasebranchcode == ''){
          Swal.fire({
              title: "",
              text: "Please select a branch for cash release.",
              icon: "error",
              
            }); 
            
        } else {
          this.isCancelBtnDisabled = true;
          this.isEncashBtnDisabled = true;

          const response = await encash(param);
          if(response != null){
            if (response.data.result == "SUCCESS") {
              Swal.fire({
                title: "",
                text: response.data.message,
                icon: "success",
                
              }); 
              window.location.reload();
            } else if(response.data.result == "FAILED"){
              
              Swal.fire({
                title: "",
                text: response.data.message,
                icon: "error",
                
              }); 
              this.cancel_encashmentconfirmation();
            } 
          }
        }
        this.loading = false;
        return;
      },

      async reset_encashment(){
        this.model_encashment_amount = "";
        this.model_destination_selected = "XXX";
        this.isShowEncashmentConfirmation = false;
        this.isShowBiboEncashmentConfirmation = false;
        this.isShowChequeEncashmentConfirmation = false;
        this.isShowCashEncashmentConfirmation = false;
        this.isCancelBtnDisabled = true;
        this.isEncashBtnDisabled = true;
        this.isShowDivChequeBranch = false;
        this.isShowDivCashBranch = false;
        this.isShowEncashmentButtons = false;
    

        var element;
        element = document.getElementById("mlcRow");
        element.style.backgroundColor = "#ffffff";
        element = document.getElementById("drcRow");
        element.style.backgroundColor = "#ffffff";

        if(this.ccode == 0){
          this.showEncashmentForm = false;
        } else {
          this.showEncashmentForm = true;
        }
        
      },

      async process_chinatrustOTP(){
        this.loading = true;
        var param = {"otp" : this.model_chinatrust_otp};
        const response = await processChinatrustOTP(param);
        if(response != null){
          this.isCancelBtnDisabled = true;
          this.isEncashBtnDisabled = true;

          if (response.data.result == "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
              
            }); 
            window.location.reload();
          } else if(response.data.result == "FAILED"){
            
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              
            }); 
            this.cancel_encashmentconfirmation();
          }
        }
        this.loading = false;
        return;
      },

      async process_encashmentOTP(){
        this.loading = true;
        var param = {"otp" : this.model_encashment_otp};
        const response = await processEncashmentOTP(param);
        
        if(response != null){    
          this.isCancelBtnDisabled = true;
          this.isEncashBtnDisabled = true;
          
          if (response.data.result == "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
              
            }); 
            window.location.reload();
          } else if(response.data.result == "FAILED"){
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              
            }); 
            this.cancel_encashmentconfirmation();
          } else {

            this.need_cheque_id_upload = true;
            console.log(response.data.message);
          }
        }
        this.loading = false;
        return; 
      },
      async checkBoxValidator(event){
        if (event.target.checked) {
          this.isCancelChequeBtnDisabled = false;
          this.isChequeEncashBtnDisabled = false;
        } else {
          this.isCancelChequeBtnDisabled = true;
          this.isChequeEncashBtnDisabled = true;
        }
      },
      goToProfilePage(){
        this.$router.push('/settings/profile/') ;
        //console.log(this.$refs.closeEncashmentProcessModal);
        //this.$refs.closeEncashmentProcessModal.click();


        //window.location.reload();
        //return false;
      },


    setEncashmentFrom(encashFrom){
      this.encashFrom = encashFrom;
      var rowID = "";
      var element;
      
      if(encashFrom == 'MLC'){

        element = document.getElementById("drcRow");
        element.style.backgroundColor = "#ffffff";

        rowID = "mlcRow";
        element = document.getElementById(rowID);
      } else if(encashFrom == 'DRC'){

        element = document.getElementById("mlcRow");
        element.style.backgroundColor = "#ffffff";

        rowID = "drcRow";
        element = document.getElementById(rowID);
      }
      element.style.backgroundColor = "#d0fff9";
      this.showEncashmentForm = true;

    },



    handleChequeIDUpload(){
			let  image = (this.$refs as any).cheque_id.files[0]; 
			this.uploadChequeIDImage(image);
		},
    
		async uploadChequeIDImage(image){
			 
			const formData = new FormData(); 
			formData.append('file', image); 
      
      this.currentlyUploading = true;
      
			const self = this;
			axios.post( process.env.VUE_APP_BASE_API + "getimage/uploadchequeid",
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
          'ECAPP-TWXHEADER': localStorage.getItem('ecmtctoken')
				}
			}
			).then(function(response){ 
          if(response.data !== undefined && response.data.result == "SUCCESS"){
           self.need_cheque_id_selfie_upload = true;
           self.currentlyUploading = false;
          }
			})
			.catch(function(){
			 
				 
			}); 
		},

    handleChequeIDSelfieUpload(){
			let  image = (this.$refs as any).cheque_id_selfie.files[0]; 
			this.uploadChequeIDSelfieImage(image);
		},
    
		async uploadChequeIDSelfieImage(image){
			 
			const formData = new FormData(); 
			formData.append('file', image); 
      
      this.currentlyUploading = true;

			const self = this;
			axios.post( process.env.VUE_APP_BASE_API + "getimage/uploadchequeidselfie",
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
          'ECAPP-TWXHEADER': localStorage.getItem('ecmtctoken')
				}
			}
			).then(function(response){ 
          if(response.data !== undefined && response.data.result == "SUCCESS"){
            self.currentlyUploading = false;
            window.location.reload();
          }
			})
			.catch(function(){
			 
				 
			}); 
		},



  },
  mounted(){
      this.encashmentprocessinitialload();
  },
  components: {},
  props: {
    widgetClasses: String
  }
});
