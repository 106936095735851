
import { defineComponent } from "vue";
import {encashmenthistory}  from "@/api/encashment";
import EncashmentProcess from "@/components/encashment/EncashmentProcess.vue";

export default defineComponent({
  name: "encashmenthistory", 
  methods: {
    async encashmenthistory(){ 
      this.loading = true;
      const response = await encashmenthistory(1);
      this.list = response.data.pageList;
      this.hasPendingChinatrustOTP = response.data.hasPendingChinatrustOTP;
      this.hasPendingEncashmentOTP = response.data.hasPendingEncashmentOTP;
      this.loading = false;
    },
    async refreshEncashmentProcessModal(){ 
      EncashmentProcess.methods?.encashmentprocessinitialload();
    },
  },
  mounted(){
      this.encashmenthistory();
      if(localStorage.getItem("override_cifid") != null){
        this.isOverriden = true;
      }
  },
  data(){
      return { 
        list : [],
        hasPendingChinatrustOTP : "",
        hasPendingEncashmentOTP : "",
        loading: true,
        isOverriden : false,
      }
  },
  components: {
    'encashment-process' : EncashmentProcess,
  },
  props: {
    widgetClasses: String
  }
});

