import request from '@/utils/request'

//Encashment History
export function encashmenthistory(page) {
  return request({ url: 'vencashment', method: 'post', data: {'page': page} })
} 

//Encashment Process Initial Load
export function encashmentprocessinitialload() {
  return request({ url: 'vencashmentinitialload', method: 'post', data: {} })
} 

//Encash
export function encash(param) {
  return request({ url: 'encash', method: 'post', data: param })
} 

// Process Chinatrust OTP
export function processChinatrustOTP(param) {
  return request({ url: 'chinatrustvalidateotp', method: 'post', data: param })
} 


// Process Encashment OTP
export function processEncashmentOTP(param) {
  return request({ url: 'encashmentvalidateotp', method: 'post', data: param })
} 


 